'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VType = void 0;

(function (VType) {
  VType["default"] = "default";
  VType["success"] = "success";
  VType["error"] = "error";
  VType["warning"] = "warning";
  VType["info"] = "info";
})(exports.VType || (exports.VType = {}));

exports.VActionType = void 0;

(function (VActionType) {
  VActionType["url"] = "url";
  VActionType["submit"] = "submit";
  VActionType["callback"] = "callback";
})(exports.VActionType || (exports.VActionType = {}));
