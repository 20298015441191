import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const Welcome = lazy(
  () => import(/* webpackChunkName: "Welcome" */ '@pages/welcome')
)

const SignUp = lazy(
  () => import(/* webpackChunkName: "SignUp" */ '@pages/signup')
)

const LogIn = lazy(() => import(/* webpackChunkName: "LogIn" */ '@pages/login'))

const routes: RouteObject[] = [
  {
    path: '/welcome',
    element: <Welcome />
  },
  {
    path: '/signup',
    element: <SignUp />
  },
  {
    path: '/login',
    element: <LogIn />
  }
]
export default routes
