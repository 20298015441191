'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VPadding = void 0;

(function (VPadding) {
  VPadding["default"] = "md";
  VPadding["none"] = "none";
  VPadding["xxs"] = "xxs";
  VPadding["xs"] = "xs";
  VPadding["sm"] = "sm";
  VPadding["md"] = "md";
  VPadding["lg"] = "lg";
  VPadding["xl"] = "xl";
  VPadding["xxl"] = "xxl";
})(exports.VPadding || (exports.VPadding = {}));

(function (VPadding) {
  function toStyle(config) {
    return "vpadding-".concat(config);
  }

  VPadding.toStyle = toStyle;
})(exports.VPadding || (exports.VPadding = {}));
