import axios from 'axios'

import { DomainType, TokenType } from '@vezham/ui/dist/contracts/iam'

import { People, RQprofile } from '../contracts'

const profile = '/accounts/v1/people/profile'

export const people = {
  profile: async (rq: RQprofile): Promise<People> => {
    return axios.get(profile, {
      tokenType: TokenType.ACCOUNT,
      domainType: DomainType.DC
    })
  }
}
