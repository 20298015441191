import axios from 'axios'

import { DomainType, TokenType } from '@vezham/ui/dist/contracts/iam'

import { Business, RQprofile } from '../contracts'

const profile = '/businesses/v1/business/profile'

export const business = {
  profile: async (rq: RQprofile): Promise<Business> => {
    return axios.get(profile, {
      tokenType: TokenType.BUSINESS,
      domainType: DomainType.DC
    })
  }
}
