'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.Status = void 0;

(function (Status) {
  Status["UNCHANGED"] = "Unchanged";
  Status["UPDATED"] = "Updated";
  Status["SUCCESS"] = "Success";
})(exports.Status || (exports.Status = {}));
