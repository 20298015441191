'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('./status/index.js');

var Installed = window.matchMedia('(display-mode: window-controls-overlay)').matches || window.matchMedia('(display-mode: standalone)').matches;
var noSWsupport = !('serviceWorker' in navigator);
var isLocalhost = Boolean(window.location.hostname === 'localhost' || // [::1] is the IPv6 localhost address.
window.location.hostname === '[::1]' || // 127.0.0.0/8 are considered localhost for IPv4.
window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));

function getSWurl() {
  var url = "".concat(process.env.PUBLIC_URL, "/sw.js"); // if (process.env.NODE_ENV === "production") {
  //   url = `${process.env.PUBLIC_URL}/sw.js`;
  // }

  return url;
}

function doRegister(swUrl, config) {
  navigator.serviceWorker.register(swUrl).then(function (registration) {
    registration.onupdatefound = function () {
      var installingWorker = registration.installing;

      if (installingWorker == null) {
        return;
      }

      installingWorker.onstatechange = function () {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // but the previous service worker will still serve the older content until all client tabs are closed.
            // console.log(
            //   'service_worker | New content is available and will be used when all tabs for this page are closed. See https://cra.link/PWA.'
            // )
            config.onUpdate(registration);
          } else {
            // At this point, everything has been precached.
            // It's the perfect time to display a "Content is cached for offline use." message.
            console.log('service_worker | Content is cached for offline use.');
            config.onSuccess(registration);
          }
        }
      };

      console.log('service_worker | registration successful with scope: ', registration.scope);
    };
  }).catch(function (error) {
    console.error('service_worker | Error during registration:', error);
  });
}

function doValidation(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: {
      'Service-Worker': 'script'
    }
  }).then(function (response) {
    // Ensure service worker exists, and that we really are getting a JS file.
    var contentType = response.headers.get('content-type');

    if (response.status === 404 || contentType != null && contentType.indexOf('javascript') === -1) {
      // No service worker found. Probably a different app. Reload the page.
      navigator.serviceWorker.ready.then(function (registration) {
        registration.unregister().then(function () {
          window.location.reload();
        });
      });
    } else {
      // Service worker found. Proceed as normal.
      doRegister(swUrl, config);
    }
  }).catch(function () {
    console.log('service_worker | No internet connection found. App is running in offline mode.');
  });
}

function register(config) {
  // The URL constructor is available in all browsers that support SW.
  var publicUrl = new URL(process.env.PUBLIC_URL || '', window.location.href);

  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return;
  }

  window.addEventListener('load', function () {
    var swUrl = getSWurl();

    if (isLocalhost) {
      doValidation(swUrl, config); // This is running on localhost. Let's check if a service worker still exists or not.
      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.

      navigator.serviceWorker.ready.then(function () {
        console.log('service_worker | This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA');
      });
    } else {
      doRegister(swUrl, config); // Is not localhost. Just register service worker
    }
  });
}

function unregister() {
  if (noSWsupport) return;
  navigator.serviceWorker.ready.then(function (registration) {
    registration.unregister();
  }).catch(function (error) {
    console.error(error.message);
  });
}
var init = function (props) {
  if (noSWsupport) return;

  if (!props.config) {
    props.config = {
      onSuccess: index.onSuccess,
      onUpdate: index.onUpdate
    };
  }

  register(props.config);
};

exports.Installed = Installed;
exports.init = init;
exports.unregister = unregister;
