import { IFrameKeys } from '@vezham/ui/dist/contracts/iam'

import onMessage from './onMessage'
import { sendMessage } from './utils'

export const init = () => {
  let iframe = document.getElementById(IFrameKeys.HOST) as HTMLIFrameElement
  if (iframe) {
    iframe.src = process.env.REACT_APP_IAM_URL
    return
  }

  iframe = document.createElement('iframe')
  iframe.src = process.env.REACT_APP_IAM_URL
  iframe.width = `0px`
  iframe.height = `0px`
  iframe.id = IFrameKeys.HOST
  iframe.onload = () => {
    sendMessage({ method: 'GET_TOKEN', data: { isInit: true } })
  }
  document.body.appendChild(iframe)
}

export const onIAMmessenge = (mountHook: () => void) => (e: MessageEvent) => {
  if (e.origin !== process.env.REACT_APP_IAM_URL) {
    return
  }

  onMessage(mountHook, e)
}
