'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index$1 = require('./global/index.js');

// console.table(process.env);
var IS_PRODUCTION = process.env.NODE_ENV === 'production';
var IS_BETA = process.env.IS_BETA || false;

exports.Global = index$1;
exports.IS_BETA = IS_BETA;
exports.IS_PRODUCTION = IS_PRODUCTION;
