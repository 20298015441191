'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VAlign = void 0;

(function (VAlign) {
  VAlign["default"] = "left";
  VAlign["center"] = "center";
  VAlign["left"] = "left";
  VAlign["right"] = "right";
  VAlign["spaceEvenly"] = "space-evenly";
  VAlign["spaceBtw"] = "space-btw";
  VAlign["tl"] = "tl";
  VAlign["tr"] = "tr";
  VAlign["bl"] = "bl";
  VAlign["br"] = "br";
})(exports.VAlign || (exports.VAlign = {}));

(function (VAlign) {
  function toStyle(config) {
    return "valign-".concat(config);
  }

  VAlign.toStyle = toStyle;
})(exports.VAlign || (exports.VAlign = {}));
