'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VBorder = void 0;

(function (VBorder) {
  VBorder["default"] = "default";
  VBorder["none"] = "none";
  VBorder["top"] = "top";
  VBorder["bottom"] = "bottom";
  VBorder["left"] = "left";
  VBorder["right"] = "right";
})(exports.VBorder || (exports.VBorder = {}));

(function (VBorder) {
  function toStyle(config) {
    return "vborder-".concat(config);
  }

  VBorder.toStyle = toStyle;
})(exports.VBorder || (exports.VBorder = {}));
