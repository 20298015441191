'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var React = require('react');

function useMediaQuery(query) {
  var getMatches = function (query) {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }

    return false;
  };

  var _a = React.useState(getMatches(query)),
      matches = _a[0],
      setMatches = _a[1];

  function handleChange() {
    setMatches(getMatches(query));
  }

  React.useEffect(function () {
    var matchMedia = window.matchMedia(query); // Triggered at the first client-side load and if query changes

    handleChange(); // Listen matchMedia

    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return function () {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  return matches;
}

exports["default"] = useMediaQuery;
