'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VShadow = void 0;

(function (VShadow) {
  VShadow["default"] = "md";
  VShadow["none"] = "none";
  VShadow["xs"] = "xs";
  VShadow["sm"] = "sm";
  VShadow["md"] = "md";
  VShadow["lg"] = "lg";
  VShadow["xl"] = "xl";
  VShadow["xxl"] = "xxl";
  VShadow["xxxl"] = "xxxl";
})(exports.VShadow || (exports.VShadow = {}));

(function (VShadow) {
  function toStyle(config) {
    return "vshadow-".concat(config);
  }

  VShadow.toStyle = toStyle;
})(exports.VShadow || (exports.VShadow = {}));
