import axios, { AxiosRequestConfig } from 'axios'

import VBanner from '@vezham/ui/dist/components/v1/VBanner'
import { VType } from '@vezham/ui/dist/components/v1/VBanner/interface'
import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import { DomainType, TokenKeys, TokenType } from '@vezham/ui/dist/contracts/iam'

import useBusiness from '@store/hq/business/vstore'
import usePeople from '@store/hq/people/vstore'

const getToken = async (type: TokenType) => {
  let token = ''
  switch (type) {
    case TokenType.NO_TOKEN:
      token = ''
      break
    case TokenType.ACCOUNT:
      token = await usePeople.get()
      break
    case TokenType.BUSINESS:
      token = await useBusiness.get()
      break
  }
  return token
}

const refreshToken = async (type: TokenType) => {
  let token = ''
  switch (type) {
    case TokenType.NO_TOKEN:
      token = ''
      break
    case TokenType.ACCOUNT:
      await usePeople.refresh().then(data => {
        token = data.access_token
      })
      break
    case TokenType.BUSINESS:
      await useBusiness.refresh().then(data => {
        token = data.access_token
      })
      break
  }
  return token
}

export const handleRequest = async (config: AxiosRequestConfig) => {
  if (config.tokenType !== TokenType.NO_TOKEN) {
    let token = await getToken(config.tokenType)
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
  }
  if (config.domainType === DomainType.SANDBOX) {
    config.baseURL =
      process.env.REACT_APP_IS_SANDBOX === 'true'
        ? process.env.REACT_APP_SANDBOX_API_URL
        : process.env.REACT_APP_API_URL
  } else if (config.domainType === DomainType.DC) {
    config.baseURL =
      process.env.REACT_APP_IS_SANDBOX === 'true'
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_DC_API_URL?.replace(
            '{DC}',
            useVStore.get(TokenKeys.ACCOUNT_DC) ?? ''
          )
  }
  return config
}

export const handleResponseError = async (error: any) => {
  // Reject promise if usual error
  if (error.response.status === 500) {
    VBanner({ title: 'Something went wrong!', type: VType.error })
    // window.location.href = location.href = AppRoutes.NotFound
  } else if (error.response.status <= 401 || error.response.status === 404) {
    console.log(error.response.data.meta)
    return Promise.reject(error.response.data.meta)
  }

  /*
   * When response code is 401, try to refresh the token.
   * Eject the interceptor so it doesn't loop in case
   * token refresh causes the 401 response
   */
  let newResponse
  try {
    let tokenType = ''
    if (error.request.config && error.request.config.tokenType) {
      tokenType = error.request.config.tokenType
    } else if (error.response.config && error.response.config.tokenType) {
      tokenType = error.response.config.tokenType
    }

    if (tokenType !== TokenType.NO_TOKEN) {
      let token = await refreshToken(tokenType as TokenType)
      if (token) {
        error.response.config.headers['Authorization'] = `Bearer ${token}`
      }
    }
    newResponse = axios(error.response.config)
  } catch (error: any) {
    if (error?.data?.meta) {
      newResponse = Promise.reject(error.data.meta)
    } else if (error.data) {
      newResponse = Promise.reject(error.data)
    } else {
      newResponse = Promise.reject(error)
    }
  }
  return newResponse
}
