'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VDirection = void 0;

(function (VDirection) {
  VDirection["default"] = "horizontal";
  VDirection["vertical"] = "vertical";
  VDirection["horizontal"] = "horizontal";
})(exports.VDirection || (exports.VDirection = {}));

(function (VDirection) {
  function toStyle(config) {
    return "vdir-".concat(config);
  }

  VDirection.toStyle = toStyle;
})(exports.VDirection || (exports.VDirection = {}));
