'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

require('react');
var _interface = require('./interface.js');

_interface.Status.UNCHANGED;
var subscriptions = new Set();

function dispatch(status) {
  subscriptions.forEach(function (setStatus) {
    return setStatus(status);
  });
}


function onUpdate(_registration) {
  dispatch(_interface.Status.UPDATED);
} // wjdlz | NOTE: Updated and running


function onSuccess(_registration) {
  dispatch(_interface.Status.SUCCESS);
}

exports.onSuccess = onSuccess;
exports.onUpdate = onUpdate;
