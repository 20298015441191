'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.Vdir = void 0;

(function (Vdir) {
  Vdir["LTR"] = "ltr";
  Vdir["RTL"] = "rtl";
})(exports.Vdir || (exports.Vdir = {}));

exports.Vmode = void 0;

(function (Vmode) {
  Vmode["LIGHT"] = "light";
  Vmode["DARK"] = "dark";
  Vmode["SYSTEM"] = "system";
})(exports.Vmode || (exports.Vmode = {}));

exports.Vcolor = void 0;

(function (Vcolor) {
  Vcolor["BLUE"] = "blue";
  Vcolor["PURPLE"] = "purple";
  Vcolor["GREEN"] = "green";
  Vcolor["TEAL"] = "teal";
  Vcolor["RED"] = "red";
  Vcolor["ORANGE"] = "orange";
  Vcolor["YELLOW"] = "yellow";
  Vcolor["PINK"] = "pink";
})(exports.Vcolor || (exports.Vcolor = {}));

(function (Vcolor) {
  function toStyle(config) {
    return "vcolor-".concat(config);
  }

  Vcolor.toStyle = toStyle;

  function toList() {
    return [Vcolor.BLUE, Vcolor.PURPLE, Vcolor.GREEN, Vcolor.TEAL, Vcolor.RED, Vcolor.ORANGE, Vcolor.YELLOW, Vcolor.PINK];
  }

  Vcolor.toList = toList;
})(exports.Vcolor || (exports.Vcolor = {}));

exports.Vsidebar = void 0;

(function (Vsidebar) {
  Vsidebar["SHOW"] = "show";
  Vsidebar["HIDE"] = "hide";
})(exports.Vsidebar || (exports.Vsidebar = {}));

exports.VfontFamily = void 0;

(function (VfontFamily) {
  VfontFamily["DEFAULT"] = "sans";
  VfontFamily["SANS"] = "sans";
  VfontFamily["ROBOTO"] = "roboto";
})(exports.VfontFamily || (exports.VfontFamily = {}));

(function (VfontFamily) {
  function toStyle(config) {
    return "vfont-family-".concat(config);
  }

  VfontFamily.toStyle = toStyle;
})(exports.VfontFamily || (exports.VfontFamily = {}));

exports.VfontSize = void 0;

(function (VfontSize) {
  VfontSize["DEFAULT"] = "md";
  VfontSize["SMALL"] = "sm";
  VfontSize["MEDIUM"] = "md";
  VfontSize["LARGE"] = "lg";
})(exports.VfontSize || (exports.VfontSize = {}));

(function (VfontSize) {
  function toStyle(config) {
    return "vfont-size-".concat(config);
  }

  VfontSize.toStyle = toStyle;
})(exports.VfontSize || (exports.VfontSize = {}));
