import axios from 'axios'

import { handleRequest, handleResponseError } from './handler'

axios.interceptors.request.use(handleRequest)
axios.interceptors.response.use(
  response => response.data.data,
  handleResponseError
)

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common['content-type'] = 'application/json'
