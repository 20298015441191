'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var React = require('react');

function useBoolean(defaultValue) {
  var _a = React.useState(!!defaultValue),
      value = _a[0],
      setValue = _a[1];

  var setTrue = React.useCallback(function () {
    return setValue(true);
  }, []);
  var setFalse = React.useCallback(function () {
    return setValue(false);
  }, []);
  var toggle = React.useCallback(function () {
    return setValue(function (x) {
      return !x;
    });
  }, []);
  return {
    value: value,
    setValue: setValue,
    setTrue: setTrue,
    setFalse: setFalse,
    toggle: toggle
  };
}

exports["default"] = useBoolean;
