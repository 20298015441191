'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VBlur = void 0;

(function (VBlur) {
  VBlur["default"] = "md";
  VBlur["none"] = "none";
  VBlur["sm"] = "sm";
  VBlur["md"] = "md";
  VBlur["lg"] = "lg";
  VBlur["xl"] = "xl";
})(exports.VBlur || (exports.VBlur = {}));

(function (VBlur) {
  function toStyle(config) {
    return "vblur-".concat(config);
  }

  VBlur.toStyle = toStyle;
})(exports.VBlur || (exports.VBlur = {}));
