'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VRounded = void 0;

(function (VRounded) {
  VRounded["default"] = "md";
  VRounded["none"] = "none";
  VRounded["circle"] = "circle";
  VRounded["xs"] = "xs";
  VRounded["sm"] = "sm";
  VRounded["md"] = "md";
  VRounded["lg"] = "lg";
  VRounded["xl"] = "xl";
  VRounded["xxl"] = "xxl";
  VRounded["xxxl"] = "xxxl";
})(exports.VRounded || (exports.VRounded = {}));

(function (VRounded) {
  function toStyle(config) {
    return "vrounded-".concat(config);
  }

  VRounded.toStyle = toStyle;
})(exports.VRounded || (exports.VRounded = {}));
