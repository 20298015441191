'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

require('react');

var IsMobile = function () {
  return window.innerWidth <= 750;
};

exports.IsMobile = IsMobile;
