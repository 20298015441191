'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.Status = void 0;

(function (Status) {
  Status["ENABLED"] = "enabled";
  Status["DISABLED"] = "disabled";
  Status["DELETED"] = "deleted";
})(exports.Status || (exports.Status = {}));

exports.State = void 0;

(function (State) {
  State["DRAFT"] = "draft";
  State["PUBLISHED"] = "published";
})(exports.State || (exports.State = {}));
