'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.VSpacer = void 0;

(function (VSpacer) {
  VSpacer["default"] = "md";
  VSpacer["none"] = "none";
  VSpacer["xxs"] = "xxs";
  VSpacer["xs"] = "xs";
  VSpacer["sm"] = "sm";
  VSpacer["md"] = "md";
  VSpacer["lg"] = "lg";
  VSpacer["xl"] = "xl";
  VSpacer["xxl"] = "xxl";
  VSpacer["xxxl"] = "xxxl";
})(exports.VSpacer || (exports.VSpacer = {}));

(function (VSpacer) {
  function toStyle(config) {
    return "vspacer-".concat(config);
  }

  VSpacer.toStyle = toStyle;
})(exports.VSpacer || (exports.VSpacer = {}));
