import { IFrameKeys } from '@vezham/ui/dist/contracts/iam'

export const sendMessage = (message: any) => {
  const iframe = document.getElementById(IFrameKeys.HOST) as HTMLIFrameElement
  if (iframe) {
    iframe.contentWindow.postMessage(
      JSON.stringify(message),
      process.env.REACT_APP_IAM_URL
    )
  }
}
