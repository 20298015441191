import useBusiness from '@store/hq/business/vstore'
import usePeople from '@store/hq/people/vstore'

const onMessage = (mountHook: () => void, e: MessageEvent<any>) => {
  const payload = typeof e.data == 'string' ? JSON.parse(e.data) : e.data
  const { method, data } = payload

  switch (method) {
    case 'RELOAD':
      window.location.reload()
      break
    case 'RESET_TOKEN':
      usePeople.reset()
      useBusiness.reset()
      data.isInit && mountHook()
      break
    case 'INIT_TOKEN':
      usePeople.update(data)
      usePeople.updateDC(data)
      useBusiness.update(data)
      mountHook()
      break
  }
}

export default onMessage
