'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var pretext = 'app';
var version = '0.1';

function getKey(key) {
  return "vezham-ls-".concat(pretext, "::").concat(version, "-").concat(key);
}

var init = function (props) {
  if (props.pretext) {
    pretext = props.pretext;
  }

  if (props.version) {
    version = props.version;
  }
}; // wjdlz | NOTE: has is for keys and contains for values.

var useVStore = {
  get: function (key, initialValue) {
    try {
      var item = window.localStorage.getItem(getKey(key));

      if (item) {
        if (item !== 'undefined') {
          return item;
        }
      }

      return initialValue || '';
    } catch (error) {
      console.warn("key | ".concat(getKey(key), " | err: "), error);
      return initialValue || '';
    }
  },
  has: function (key) {
    return useVStore.get(key) ? true : false;
  },
  set: function (key, value) {
    return window.localStorage.setItem(getKey(key), value);
  },
  delete: function (key) {
    return window.localStorage.removeItem(getKey(key));
  },
  purge: function () {
    return window.localStorage.clear();
  }
};

exports["default"] = useVStore;
exports.init = init;
