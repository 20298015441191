import moment from 'moment'

import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import { AuthToken, HQkeys, TokenKeys } from '@vezham/ui/dist/contracts/iam'

import { iam as useIAM } from '@store/iam/actions'

import { sendMessage } from '@utils/iam/utils'

const useBusiness = {
  reset: () => {
    useVStore.delete(TokenKeys.BUSINESS_REFRESH_TOKEN)
    useVStore.delete(TokenKeys.BUSINESS_ACCESS_TOKEN)
    useVStore.delete(TokenKeys.BUSINESS_EXPIRES_IN)
    useVStore.delete(HQkeys.ACTIVE_BUSINESS)
    return
  },
  update: (data: any) => {
    if (!data) return

    useVStore.set(
      TokenKeys.BUSINESS_REFRESH_TOKEN,
      data[TokenKeys.BUSINESS_REFRESH_TOKEN]
    )
    useVStore.set(
      TokenKeys.BUSINESS_ACCESS_TOKEN,
      data[TokenKeys.BUSINESS_ACCESS_TOKEN]
    )
    useVStore.set(
      TokenKeys.BUSINESS_EXPIRES_IN,
      data[TokenKeys.BUSINESS_EXPIRES_IN]
    )
  },
  authenticate: async (data: AuthToken) => {
    const saveToken = (data: AuthToken) => {
      useVStore.set(TokenKeys.BUSINESS_ACCESS_TOKEN, data.access_token)
      useVStore.set(TokenKeys.BUSINESS_EXPIRES_IN, data.expires_in)
      if (data.refresh_token) {
        useVStore.set(TokenKeys.BUSINESS_REFRESH_TOKEN, data.refresh_token)
      }
    }
    const sendToIAM = (data: AuthToken) => {
      const payload: any = {
        [TokenKeys.BUSINESS_ACCESS_TOKEN]: data.access_token,
        [TokenKeys.BUSINESS_EXPIRES_IN]: data.expires_in
      }
      if (data.refresh_token) {
        payload[TokenKeys.BUSINESS_REFRESH_TOKEN] = data.refresh_token
      }
      sendMessage({ method: 'SAVE_BUSINESS_TOKEN', data: payload })
    }

    saveToken(data)
    sendToIAM(data)
  },
  refresh: async () => {
    const refreshToken = useVStore.get(TokenKeys.BUSINESS_REFRESH_TOKEN)
    const tokenResponse = await useIAM.refresh({
      refresh_token: refreshToken
    })
    useBusiness.authenticate(tokenResponse)
    return tokenResponse
  },
  get: async () => {
    const expiryTime = useVStore.get(TokenKeys.BUSINESS_EXPIRES_IN)
    const expiryInMins =
      moment.unix(parseInt(expiryTime ?? '')).diff(moment()) / 60000
    if (expiryInMins < 5) {
      await useBusiness.refresh()
    }
    return useVStore.get(TokenKeys.BUSINESS_ACCESS_TOKEN)
  }
}

export default useBusiness
